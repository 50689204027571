import classNames from "classnames";

import styles from "./index.module.scss";

interface LoaderProps {
	className?: string;
}

export default function Loader({ className }: LoaderProps) {
	return (
		<div className={classNames(styles.loader, className)}>
			<div className={classNames(styles.spinner, "loadingSpinner")}></div>
		</div>
	);
}
